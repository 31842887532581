<template>
    <canvas 
        :id="id" 
        @mousedown="startDrawing" 
        @touchstart="startDrawing" 
        @mouseup="stopDrawing"
        @mouseout="stopDrawing" 
        @touchend="stopDrawing"
        @touchcancel="stopDrawing"
        @mousemove="continueDrawing" 
        @touchmove="continueDrawing" 
    ></canvas> 
</template>

<style scoped lang="scss">
    canvas 
    {
        width: 100%; 
        cursor: crosshair;
    }
</style>

<script>

    export default {
        props: {
            modelValue: {
                required: true,
            },
            id: {
                required: true,
            },
            imagewidth: {
                required: true,
            },
            imageheight: {
                required: true,
            },
            imagesrc: {
                required: true,
            },
        },
        data() {
            return {
                isDrawing: false,
                x: null, 
                y: null,
                canvas: null,
                canvasContext: null,
            }
        },
        emits: ['update:modelValue'],
        mounted() {
            this.setupCanvas();
        },
        methods: {
            setupCanvas: function()
            {
                var imageAspect = this.imagewidth / this.imageheight;

                this.canvas = document.getElementById(this.id);
                this.canvasContext = this.canvas.getContext("2d");  
                this.canvas.width = 1000;
                this.canvas.height = this.canvas.width / imageAspect;

                this.applyImageToCanvas();
            },
            applyImageToCanvas: function()
            {
                const img = new Image();
                img.src = this.imagesrc;
                img.onload = () => {
                    this.canvasContext.drawImage(img, 0, 0, this.canvas.width, this.canvas.height);
                    this.emitValue();
                }
            },
            startDrawing: function(e)
            {
                this.isDrawing = true;
                var newRelativePosition = this.getRelativePosition(e);
                this.x = newRelativePosition.x;
                this.y = newRelativePosition.y;
                e.preventDefault();
            },
            stopDrawing: function(e)
            {
                this.isDrawing = false;
                this.emitValue();
                e.preventDefault();
            },
            continueDrawing: function(e)
            {
                var newRelativePosition = this.getRelativePosition(e);
                
                if(this.isDrawing)
                {
                    this.canvasContext.beginPath();
                    this.canvasContext.moveTo(this.x, this.y);
                    this.canvasContext.lineCap = 'round';
                    this.canvasContext.lineWidth = 5;
                    this.canvasContext.lineTo(newRelativePosition.x, newRelativePosition.y);
                    this.canvasContext.strokeStyle = 'rgb(255, 0, 0)';
                    this.canvasContext.stroke();

                    this.x = newRelativePosition.x;
                    this.y = newRelativePosition.y;
                }
                e.preventDefault();
            },
            getRelativePosition: function(e) {
                var rect = e.target.getBoundingClientRect();
                var newX, newY;

                if(e.touches && e.touches.length)
                {
                    newX = e.touches[0].clientX - rect.left;
                    newY = e.touches[0].clientY - rect.top;
                }
                else
                {
                    newX = e.clientX - rect.left;
                    newY = e.clientY - rect.top;
                }

                var scalingFactor = rect.width / this.canvasContext.canvas.width;

                return {
                    x: Math.round(newX / scalingFactor),
                    y: Math.round(newY / scalingFactor),
                };
            },
            emitValue: function() {
                this.canvas.toBlob((blob) => {
                    this.$emit('update:modelValue', blob);
                })
            }
        },
        watch: {
            imagesrc() {
                this.canvasContext.clearRect(0, 0, this.canvas.width, this.canvas.height);
                this.applyImageToCanvas();
            },
        },
    }
</script>

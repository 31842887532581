<template>
    <div v-if="this.canGetDates">
        <div class="row">
            <div class="col-xs-5 col-md-4">
                <TextInput
                    :label="this.label"
                    v-model="this.localModelValue"
                    :v="this.v"
                    type="date"
                    :min="this.minDeliveryDate"
                    :max="this.maxDeliveryDate"
                />
            </div>
        </div>
        <div class="row mt-4" v-if="this.dateSelected()">
            <div class="col-xs-5 col-md-4">
                <TurnaroundCalendar
                v-if="this.dates != null"
                :dates="this.dates"
                :order_type="this.order_type"
                />
            </div>
        </div>
    </div>
    <div v-if="!this.canGetDates">
        You must select products and a service level to check delivery date.
    </div>
</template>
<script>
    const todayDate = () => {
        var today = new Date();
        today.setHours(0, 0, 0, 0);

        return new Date(today.setDate(today.getDate()+1)); 
    };

    const rangeEndDate = () => {
        var today = new Date();
        today.setHours(0, 0, 0, 0);

        return new Date(today.setMonth(today.getMonth()+1)); 
    };

    import api from "@/services/api";
    import TextInput from '@/components/TextInput.vue';
    import TurnaroundCalendar from '@/components/TurnaroundCalendar.vue';

    export default {
        props: {
            label: {
                required: true
            },
            v: {
                required: true,
            },
            modelValue: {
                required: true,
            },
            account_id: {
                required: true,
            },
            order_type: {
                required: true,
            },
            standard: {
                required: true,
            },
            products: {
                required: true,
            },
        },
        data() {
            return {
                suggestedDeliveryDate: null,
                dates: null,
                deliveryDate: null,
            }
        },
        watch: {
            localModelValue(localModelValue) {
                this.fetchCollectionDate(localModelValue);
            },
            canGetDates() {
                this.fetchDeliveryDate();
            }
        },
        components: {            
            TextInput,
            TurnaroundCalendar,
        },
        computed: {
            minDeliveryDate () {
                var minDate = todayDate();
                
                if(this.suggestedDeliveryDate !== null && this.suggestedDeliveryDate > minDate)
                {
                    minDate = this.suggestedDeliveryDate;
                }

                return minDate.toISOString().substring(0,10);
            },
            maxDeliveryDate () {
                return rangeEndDate().toISOString().substring(0,10);
            },
            localModelValue: {
                get() {
                    return this.modelValue
                },
                set(newValue) {
                    this.$emit('update:modelValue', newValue)
                },
            },
            canGetDates() {
                return this.order_type && this.standard && this.products.length;
            }
        },
        emits: ['update:modelValue'],
        mounted () {
            if(this.canGetDates)
            {
                this.fetchDeliveryDate();
            }
            this.disableWeekends();
        },
        methods: {
            dateSelected() {
                return this.localModelValue !== null && this.localModelValue !== '';
            },
            disableWeekends: function() {
                
            },
            fetchDeliveryDate: function() {
                this.suggestedDeliveryDate = null;
                api
                .get('/api/v1/orders/delivery-date', {
                    params: {
                        practice_id: this.$store.state.currentPracticeId,
                        account_id: this.account_id,
                        order_type: this.order_type,
                        standard: this.standard,
                        products: this.products,
                    }
                })
                .then((response) => {
                    this.suggestedDeliveryDate = new Date(response.data.delivery_date_datepicker);
                    this.dates = response.data;
                })
                .catch(() => {
                    console.log('Catch delivery date error');
                });
            },
            fetchCollectionDate: function(localModelValue) {
                api
                .get('api/v1/orders/collection-date', {
                    params: {
                        practice_id: this.$store.state.currentPracticeId,
                        account_id: this.account_id,
                        order_type: this.order_type,
                        standard: this.standard,
                        products: this.products,
                        delivery_date: localModelValue,
                    }
                })
                .then((response) => {
                    this.dates = response.data;
                })
                .catch(() => {
                    console.log('Catch delivery date error');
                });
            }
        }
    }
</script>

<template>
    <InputLayout :v="v" :label="label">
        <div :class="{ 'is-invalid': v && v.$error }">
            <button type="button" class="btn me-1 mb-1" :class="{'btn-primary': modelValue === option.id, 'btn-secondary': modelValue !== option.id}" v-for="option of options" v-bind:key="option.id" @click="$emit('update:modelValue', option.id)">{{option.label}}</button>
        </div>
    </InputLayout>
</template>

<script>
    import InputLayout from '@/components/InputLayout.vue';

    export default {
        props: {
            label: {
                required: true
            },
            v: {
                required: true,
            },
            modelValue: {
                required: true,
            },
            options: {
                required: true,
            }
        },
        components: {            
            InputLayout,
        },
        emits: ['update:modelValue'],
    }
</script>

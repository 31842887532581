<template>
    <div class="mb-2 image-guide">
        <p class="small">{{ imageUploadGuide.title }}</p>
        <div>
            <div v-for="(image, index) in imageUploadGuide.images" :key="index" class="image-guide__img-container">
                <img :src="image.src" :alt="image.alt" class="img-thumbnail" />
                <div v-if="image.alt" style="position:absolute;" class="image-guide__text">{{image.alt}}</div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.image-guide  {
    display: inline-block;
    img {
    width:150px;
    height:180px;
    object-fit:cover;
    padding-bottom: 30px;
    @media screen and (max-width: 1130px){
        width:100%;
    height:160px;
    margin-top:10px;
    }
    @media screen and (max-width: 430px){
        height:195px;
    }
    }
    &__img-container {
        margin-right:8px;
        display: inline-block;
        position:relative;
        @media screen and (max-width: 530px){
            position: relative;
            width: 31%;
            margin-right: 2.3%;
        }
        @media screen and (max-width: 430px){
            position: relative;
            width: 47%;
        }
    }
    &__text {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: #333;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        color: white;
        padding: 5px;
        font-size: 14px;
        text-align: center;
    }
}
</style>

<script>
export default {
    props: {
        imageUploadGuide: {
            default: [],
        }
    },
}

</script>
<template>
    <InputLayout :v="v" :label="label">
        <textarea 
            class="form-control" 
            :id="v.$path" 
            :class="{ 'is-invalid': v && v.$error }" 
            :value="modelValue"
            :rows="rows"
            @input="$emit('update:modelValue', $event.target.value)"
        />
    </InputLayout>
</template>

<script>
    import InputLayout from '@/components/InputLayout.vue';

    export default {
        props: {
            label: {
                required: true
            },
            v: {
                required: true,
            },
            modelValue: {
                required: true,
            },
            rows: {
                default: 3,
            },
        },
        components: {            
            InputLayout,
        },
        emits: ['update:modelValue'],
    }
</script>

<template>
    <div class="calendar-wrapper">
        <label>Turnaround times for your delivery date</label>
        <table class="calendar">
            <thead>
                <th class="calendar-heading" v-for="day in this.daysOfTheWeek" :key="day">{{day}}</th>
            </thead>
            <tbody>
                <tr class="table-row" v-for="(week, i) in this.dates.dateList" :key="i">
                    <td class="table-cell" v-for="(day, j) in week" :key="j">
                        <span class="day" :class="getClass(day)" :title="day.date.split('-').reverse().join('/')+'\n'+getTooltip(day)">{{day.date.slice(-2)}}</span>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="key-wrapper mt-2 mb-2">
            <span class="key logistics"></span><p class="key-label">Logistics</p>
            <span class="key in-lab"></span><p class="key-label">In production</p>
            <span class="key closed"></span><p class="key-label">Closed</p>
        </div>
        <p class="tip" v-if="this.order_type != this.orderTypeDigital">For your order to be returned by your requested delivery date of <span class="text-danger">{{this.dates.delivery_date_display}}</span>, please ensure we receive your product in the laboratory by  <span class="text-danger">{{this.dates.estimated_received_date}}</span></p>
        <p class="tip" v-if="this.order_type != this.orderTypeDigital">Usually this would mean your order will need to be collected or sent to us on <span class="text-danger">{{this.dates.collection_date}}</span></p>
    </div>
</template>
<style scoped lang="scss">
    .tip {
        font-size: 0.9rem;
    }
    p {
        margin: 0;  
    }
    .calendar-heading {
        width: 14.29%;
        text-align: center;
        color: #000;
    }
    .key-label {
        margin: 0px 10px 0px 0px;
        font-size: 0.9rem;
    }
    .day {
        display: flex;
        width: 36px;
        height: 36px;
        border-radius: 100px;
        color: #000;
        margin-bottom: 0px;
        align-items: center;
        justify-content: center;
        font-weight: bold;
    }
    .key-wrapper {
        display: flex;
    }
    .key {
        display: flex;
        width: 10px;
        height: 10px;
        margin-top: 5px;
        margin-right: 5px;
        border-radius: 100px;
        align-items: center;
    }
    .weekend {
        color: #888;
    }
    .logistics {
        background-color: $swift-lighter-grey;
    }
    .in-lab {
        background-color: $swift-fresh-green;
    }
    .closed {
        background-color: #ff6961;
    }
</style>
<script>
    const ORDER_TYPE_DIGITAL = 1;
    export default {
        data() {
            return {
                calendarList: null,
                daysOfTheWeek: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                orderTypeDigital: ORDER_TYPE_DIGITAL,
            };
        },
        props: {
            dates: {
                type: Object,
                default: () => ({}),
            },
            order_type: {
                required: true,
            }
        },
        methods: {
            getClass(day) {
                switch (true) {
                    case day.isWeekend:
                        return 'weekend';
                    case day.closed:
                        return 'closed';
                    case day.isInLab:
                        return 'in-lab';
                    case day.isCollection:
                        return 'logistics';
                    case day.isRecieved:
                        return 'in-lab';
                    case day.isDelivered:
                        return 'logistics';
                }
            },
            getTooltip(day) {
                switch (true) {
                    case day.isWeekend:
                        return 'Weekend';
                    case day.closed:
                        return 'Closed';
                    case day.isInLab:
                        return 'In Lab';
                    case day.isCollection:
                        return 'Collection';
                    case day.isRecieved:
                        return 'In Lab (Recieved)';
                    case day.isDelivered:
                        return 'Delivery';
                    default:
                        return '';
                }
            }
        },
    }
</script>

<template>
    <InputLayout :v="v" :label="label">
        
        <div class="row">
            <div v-for="(annotation, index) of annotations" v-bind:key="index" class="col-6 col-xs-4 col-md-3 col-xl-2">
                <div class="annotation-item mb-3">
                    <img :src="annotation.image" />
                </div>
            </div>
        </div>
        <div>
            <button type="button" class="btn btn-secondary" :class="{ 'is-invalid': v && v.$error }" v-on:click="addAnnotation">
                Add Notation
            </button>
            <span class="invalid-feedback" v-if="v && v.$error">{{v.$errors[0].$message}}</span>
        </div>
    </InputLayout>
    <div class="modal fade" id="annotation-modal" tabindex="-1">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add Notation <br><span class="hint">Add a notation to the images using your mouse</span></h5>
                    <button v-if="!isUploading" type="button" class="btn-close" data-bs-dismiss="modal" @click="resetForm"></button>
                </div>
                <div class="modal-body">
                    <div class="row" v-if="selectedImageIndex === null">
                        <div v-for="(image, index) of images" v-bind:key="index" class="col-6 col-lg-4">
                            <div class="background-image-item mb-3" @click="selectImage(index)">
                                <img :src="require('@/assets/images/annotations/' + image.file_name)" />
                            </div>
                        </div>
                    </div>
                    <div v-if="selectedImageIndex !== null && !isUploading">
                        <CanvasInput
                            label="Instructions"
                            v-model="image"
                            :imagewidth="images[selectedImageIndex].width" 
                            :imageheight="images[selectedImageIndex].height"
                            :imagesrc="require('@/assets/images/annotations/' + images[selectedImageIndex].file_name)"
                            id="canvas1"
                        />
                        <TextInput
                            label="Text"
                            v-model="text"
                            :v="v$.text"
                            isPreventSubmit="true"
                        />
                    </div>
                    <div v-if="isUploading">
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" :style="{ width: calculateUploaded() }"></div>
                        </div>
                    </div>
                </div>
                <div v-if="selectedImageIndex !== null && !isUploading" class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="resetForm">Change image</button>
                    <button type="button" class="btn btn-primary" @click="submit">Save changes</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .annotation-item
    {
        border: $border-width solid $card-border-color;
        @include border-radius($border-radius);
        padding: $spacer;
        aspect-ratio: 4/3;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        
        img
        {
            object-fit: contain;
            max-width: 100%;
            max-height: 100%;
        }
    }

    .background-image-item
    {
        border: $border-width solid $card-border-color;
        @include border-radius($border-radius);
        padding: $spacer;
        aspect-ratio: 4/3;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        
        img
        {
            object-fit: contain;
            max-width: 100%;
            max-height: 100%;
        }
    }

    .hint {
        margin: 0;
        color: #8fbb13;
        font-weight: 100;
        font-size: 1rem;
    }
</style>

<script>
    // import api from "@/services/api";
    import InputLayout from '@/components/InputLayout.vue';
    import CanvasInput from '@/components/CanvasInput.vue';
    import TextInput from '@/components/TextInput.vue';
    import FileUpload from "@/services/FileUpload";
    import useVuelidate from '@vuelidate/core'
    import { required, maxLength } from '@vuelidate/validators'
    import { Modal } from 'bootstrap';

    export default {
        props: {
            modelValue: {
                required: true,
            },
            v: {
                required: true,
            },
            label: {
                required: true,
            },
        },
        setup () {
            return {
                v$: useVuelidate()
            }
        },
        mounted() {

        },
        data() {
            return {
                annotations: this.modelValue,
                image: null,
                text: '',
                selectedImageIndex: null,
                uploadData: null,
                isUploading: false,
                images: [
                    {
                        file_name: 'upper.png',
                        width: 800,
                        height: 600, 
                    },
                    {
                        file_name: 'lower.png',
                        width: 800,
                        height: 600, 
                    },
                    {
                        file_name: 'front-6.png',
                        width: 800,
                        height: 600, 
                    },
                    {
                        file_name: 'occlusal.png',
                        width: 800,
                        height: 600, 
                    },
                    {
                        file_name: 'number-chart.png',
                        width: 800,
                        height: 600, 
                    },
                ]
            }
        },
        validations () {
            return {
                image: { required },
                text: { maxLength: maxLength(200) },
            }
        },
        components: {            
            InputLayout,
            CanvasInput,
            TextInput,
        },
        emits: ['update:modelValue'],
        methods: {
            addAnnotation: function()
            {
                this.modal = new Modal(document.getElementById('annotation-modal'));
                this.modal.show();
            },
            selectImage: function(index)
            {
                this.selectedImageIndex = index;
            },
            submit: async function () {
                var isValid = await this.v$.$validate();

                if(isValid)
                {
                    this.processImage();
                }
                else
                {
                    console.log('Validation errors', this.v$.$errors.map((error) => { return error.$property + ': ' + error.$message}));
                }
            },
            processImage: function() {
                var mimetype = this.image.type;

                FileUpload.startUpload('notation.png', this.image, mimetype)
                .then((uploadData) => {
                    this.uploadData = uploadData;
                    this.isUploading = true;
                    this.upload();
                })
                .catch(() => {
                    
                });
            },
            upload: function () {
                FileUpload.uploadParts(this.uploadData) // Must remain as this.processingFiles. Using processingFile insstead breaks onUploadProgress
                .then(() => {
                    this.completeUpload();
                })
                .catch(() => {

                });
            },
            completeUpload: function () {
                FileUpload.completeUpload(this.uploadData)
                .then((fileHash) => {
                    this.annotations.push({
                        image: URL.createObjectURL(this.image),
                        hash: fileHash,
                        text: this.text,
                    })

                    this.$emit('update:modelValue', this.annotations);

                    this.modal.hide();
                    this.resetForm();
                })
                .catch(() => {
                    
                });
            },
            calculateUploaded: function() {
                var uploadedBytes = this.uploadData.parts.map(part => part.uploadedBytes).reduce((partialSum, a) => partialSum + a, 0);
                return Math.ceil((uploadedBytes / this.uploadData.file.size) * 100) + '%';
            },
            resetForm: function() {
                this.selectedImageIndex = null;
                this.image = null;
                this.text = '';
                this.isUploading = false;
                this.uploadData = null;
                this.v$.$reset();
            }
        },
    }
</script>

<template>
    <InputLayout :v="v" :label="label">
        <div>

            <ImageUploadGuide v-if="isAligner" :imageUploadGuide="imageUploadGuide" @click="clickUploadZone" />
           
            <div 
                class="file-upload-zone" 
                :class="{ 'active': dropzoneIsActive, 'is-invalid': v && v.$error }"
                @dragenter.prevent="toggleUploadZoneActive"
                @dragleave.prevent="toggleUploadZoneActive"
                @dragover.prevent
                @drop.prevent="dropUploadZone($event)"
                @click="clickUploadZone"
            >
                <input type="file" ref="fileUpload" class="d-none" multiple @change="filesChange($event.target.files)" />
                <div>Drop files here to upload or <a href="#">click here</a> to choose a file</div>
                <div>Only {{ allowedExtensionsString() }} file types are supported.</div>
            </div>
            <span class="invalid-feedback" v-if="v && v.$error">{{v.$errors[0].$message}}</span>
        </div>
        <div class="form-text" v-for="(unprocessedFile, index) of unprocessedFiles" v-bind:key="index">
            {{unprocessedFile.file.name}}: {{unprocessedFile.message}}
        </div>
        <div class="row">
            <div v-for="processingFile of allFiles" v-bind:key="processingFile.uploadId" class="col-12 col-md-6 col-xl-4">
                <div class="uploaded-file mt-4">
                    <div class="d-flex justify-content-between mb-2">
                        <span class="text-truncate">{{ processingFile.name }}</span>
                        <span v-html="calculateUploaded(processingFile, false)"></span>
                    </div>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" :style="{ width: calculateUploaded(processingFile,true) }"></div>
                    </div>
                </div>
            </div>
        </div>
    </InputLayout>
</template>

<style scoped lang="scss">
    .file-upload-zone
    {
        text-align: center;
        border: $border-width dashed $card-border-color;
        @include border-radius($border-radius);
        padding: $spacer*2 $spacer;

        &.active
        {
            background: #EEEEEE;
        }

        &.is-invalid
        {
            border-color: $danger;
        }
    }

    .uploaded-file
    {
        border: $border-width solid $swift-lighter-grey;
        @include border-radius($border-radius);
        padding: $spacer;
    }
</style>

<script>
    import ImageUploadGuide from '@/components/ImageUploadGuide.vue';
    import FileUpload from "@/services/FileUpload";
    import InputLayout from '@/components/InputLayout.vue';

    
    export default {
        props: {
            modelValue: {
                required: true,
            },
            v: {
                required: true,
            },
            label: {
                required: true,
            },
            allowedExtensions: {
                required: true,
            },
            imageUploadGuide: {
                default: [],
            },
            isAligner: {
                default: false,
            },
        },
        data() {
            return {
                dropzoneIsActive: false,
                processingFiles: [],
                uploadedFiles: this.modelValue,
                unprocessedFiles: [],
            }
        },
        emits: ['update:modelValue'],
        computed: {
            allFiles () {
                var processingFiles = this.processingFiles.filter(processingFile => !processingFile.processed)
                var uploadedFiles = this.uploadedFiles;
                return uploadedFiles.concat(processingFiles);
            }
        },
        components: {
            InputLayout,
            ImageUploadGuide,
        },
        mounted() {
            this.checkAllowedExtensions();
        },
        methods: {
            toggleUploadZoneActive: function() {
                this.dropzoneIsActive = !this.dropzoneIsActive;
            },
            clickUploadZone: function () {
                this.$refs.fileUpload.click();
            },
            dropUploadZone: function (event) {
                this.toggleUploadZoneActive();
                this.filesChange(event.dataTransfer.files)
            },
            calculateUploaded: function(processingFile, forBar) {
              if(!forBar) {
                if (processingFile.processed) {
                  return '<span><i class="bi-check2 navbar-icon"></i></span>';
                }

              } else {
                if (processingFile.processed) {
                  return '100%';
                }
              }

              var  uploadedBytes =  processingFile.uploadData.parts.map(part => part.uploadedBytes).reduce((partialSum, a) => partialSum + a, 0);
              return Math.ceil((uploadedBytes / processingFile.file.size) * 100) + '%';

            },
            allowedExtensionsString:  function() {
                return this.allowedExtensions
                .map(extension => '.' + extension)
                .join(", ")
                .replace(/, ((?:.(?!, ))+)$/, ' and $1');
            },
            checkAllowedExtensions: function() {
                var missingExtensions = this.allowedExtensions.filter((extension) =>  {
                    return FileUpload.getMimetype(extension) === null;
                });

                if(missingExtensions.length)
                {
                    alert('Missing mimetype for ' + missingExtensions);
                }
            },
            filesChange: function (fileList) {
                if (!fileList.length) {
                    return;
                }

                this.unprocessedFiles = [];
                
                Array
                .from(Array(fileList.length).keys())
                .forEach(x => {
                    this.processFile(fileList[x]);
                });
            },
            processFile: function (file) {
                var fileExtension = FileUpload.getExtension(file);
                var mimetype = FileUpload.getMimetype(fileExtension);

                if(fileExtension === null || !this.allowedExtensions.includes(fileExtension))
                {
                    this.unprocessedFiles.push({
                        file: file,
                        message: 'filetype not allowed',
                    });

                    return false;
                }

                FileUpload.startUpload(file.name, file, mimetype)
                .then((uploadData) => {
                    var processingFile = this.getNewProcessingFile(file, uploadData);

                    var fileIndex = this.processingFiles.push(processingFile) - 1;

                    this.upload(processingFile, fileIndex);
                })
                .catch((error) => {
                    this.unprocessedFiles.push({
                        file: file,
                        message: typeof error.message !== 'undefined' ? error.message : null,
                    });
                });
            },
            getNewProcessingFile: function(file, uploadData) {
                return {
                    name: file.name,
                    hash: null,
                    processed: false,
                    file: file,
                    uploadData: uploadData,
                };
            },
            upload: function (processingFile, fileIndex) {
                FileUpload.uploadParts(this.processingFiles[fileIndex].uploadData) // Must remain as this.processingFiles. Using processingFile instead breaks onUploadProgress
                .then(() => {
                    this.completeUpload(processingFile);
                })
                .catch(() => {

                });
            },
            completeUpload: function(processingFile)
            {
                FileUpload.completeUpload(processingFile.uploadData)
                .then((fileHash) => {
                    processingFile.hash = fileHash;
                    processingFile.processed = true;

                    this.uploadedFiles.push(processingFile);
                    this.$emit('update:modelValue', this.uploadedFiles);
                })
                .catch(() => {
                    
                });
            },
        },
    }
</script>

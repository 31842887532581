<template>
    <div class="row">
        <div v-for="(product, index) of products" v-bind:key="index" class="col-12 col-md-6 col-xl-4">
            <div class="card mb-4">
                <div class="card-body d-flex justify-content-between">
                    <div>{{product.name}} x{{product.quantity}}</div>
                    <div class="close-button" @click="remove(index)">
                        <i class="bi-x"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <button type="button" class="btn btn-secondary" :class="{ 'is-invalid': v && v.$error }" v-on:click="addProduct">
            Add Product
        </button>
        <span class="invalid-feedback" v-if="v && v.$error">{{v.$errors[0].$message}}</span>
    </div>
    <div class="modal fade" id="product-modal" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add product</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <nav v-if="productCategorySelections.length">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item" v-for="(productCategorySelection) of productCategorySelections" v-bind:key="productCategorySelection.id">{{productCategorySelection.name}}</li>
                        </ol>
                    </nav>
                    <div v-if="showFlash">
                      <flash bold="false" alert="warning">There are no products available in this category for this combination of service level and impression type.</flash>
                    </div>
                    <div v-if="!productSelection" class="d-grid gap-2">
                        <button type="button" class="btn btn-secondary" v-for="productCategory of productCategories" v-bind:key="productCategory.id" v-on:click.prevent="selectCategory(productCategory)">{{productCategory.name}}</button>
                        <button type="button" class="btn btn-secondary" v-for="productChoice of productChoices" v-bind:key="productChoice.id" v-on:click.prevent="selectProduct(productChoice)">{{productChoice.name}}</button>
                    </div>
                    <div v-if="productSelection">
                        <div class="mb-2">
                            <strong>{{productSelection.name}}<span v-if="productSelection.is_aligner == true"> x 1</span></strong>
                        </div>
                        <div class="mb-2">
                            <TextInput
                                label="Quantity"
                                v-model="quantity"
                                :v="v$.quantity"
                                isPreventSubmit="true"
                                v-if="productSelection.is_aligner == false"
                            />
                        </div>
                        <div class="mb-2" v-if="productSelection.materials.length">
                            <ButtonInput
                                label="Material"
                                v-model="material"
                                :v="v$.material"
                                :options="productSelection.materials"
                            />
                        </div>
                    </div>
                </div>
                <div class="modal-footer" v-if="productCategorySelections.length">
                    <button v-if="!productSelection && productCategorySelections.length" type="button" class="btn btn-light mt-2" :class="{'disabled': !productCategorySelections.length}" v-on:click.prevent="backCategory">Back</button>
                    <button v-if="productSelection" type="button" class="btn btn-primary" @click="submit">Save changes</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .close-button
    {
        .bi
        {
            cursor: pointer;
        }
    }
</style>

<script>
    import api from "@/services/api";
    import TextInput from '@/components/TextInput.vue';
    import ButtonInput from '@/components/ButtonInput.vue';
    import useVuelidate from '@vuelidate/core'
    import { required, requiredIf, minValue, maxValue } from '@vuelidate/validators'
    import { Modal } from 'bootstrap';
    import Flash from "@/components/Flash.vue";

    export default {
        props: {
            modelValue: {
                required: true,
            },
            v: {
                required: true,
            },
            service_level: {
                required: true,
            },
            displayPopup: {
                required: false,
                default: true,
            },
            accountId: {
                required: true,
            },
            order_id: {
                required: true,
            },
            impressionType: {
                required: false,
            }
        },
        setup () {
            return {
                v$: useVuelidate()
            }
        },
        mounted() {
            if(!this.products.length && this.displayPopup)
            {
                this.addProduct();
            }
        },
        data() {
            return {
                productCategories: [],
                productChoices: [],
                productCategorySelections: [],
                productSelection: null,
                quantity: 1,
                material: null,
                products: this.modelValue,
                modal: null,
                showFlash: false,
            }
        },
        validations () {
            return {
                quantity: { required, minValue: minValue(1), maxValue: maxValue(99) },
                material: { requiredIf: requiredIf(this.productSelection && this.productSelection.materials.length) },
            }
        },
        components: {
          Flash,
            TextInput,
            ButtonInput,
        },
        emits: ['update:modelValue'],
        methods: {
            fetchCategories: function(category) {       
                var categoryId = category === null ? null : category.id;

                this.productCategories = [];
                this.productChoices = [];
  
                api
                .get('/api/v1/products/categories', {
                    params: {
                        category_id: categoryId,
                        practice_id: this.$store.state.currentPracticeId,
                        account_id: this.accountId,
                        order_id: this.order_id,
                        impression_type: this.impressionType
                    },
                })
                .then((response) => {
                    if(response.data.categories && response.data.categories.length)
                    {
                        this.productCategories = response.data.categories;
                        this.showFlash = false;
                    }
                    else if(response.data.products && response.data.products.length)
                    {
                        this.productChoices = response.data.products;
                        this.showFlash = false;
                    } else {
                      this.showFlash = true;
                    }
                })
                .catch(() => {
                    console.log('Catch categories error');
                });        
            },
            resetForm: function()
            {
                this.v$.$reset();

                this.productCategories = [];
                this.productChoices = [];
                this.productCategorySelections = [];
                this.productSelection = null;
                this.quantity = 1;
                this.material = null;
            },
            selectCategory: function(category)
            {
                this.productCategorySelections.push(category);
                this.fetchCategories(category);
            },
            backCategory: function()
            {
                this.productCategorySelections.pop();
                var category = this.productCategorySelections.at(-1);
                category = typeof category === 'undefined' ? null : category;
                this.fetchCategories(category);
            },
            addProduct: function()
            {
                this.modal = new Modal(document.getElementById('product-modal'));
                this.modal.show();

                this.resetForm();
                this.fetchCategories(null);
            },
            selectProduct: function(product)
            {
                this.productCategories = [];
                this.productChoices = [];
                api
                .get('/api/v1/products/' + product.id, {
                    params: {
                        practice_id: this.$store.state.currentPracticeId,
                    }
                })
                .then((response) => {
                    product.materials = response.data.materials;
                    this.productSelection = product;
                    switch(parseInt(this.service_level))
                    {
                        case 1: this.material = response.data.default_materials.nhs; break;
                        case 2: this.material = response.data.default_materials.ind; break;
                        case 3: this.material = response.data.default_materials.prv; break;
                        default: this.material = null; break;
                    }
                })
                .catch(() => {
                    console.log('Catch product error');
                });   
            },
            submit: async function () {
                var isValid = await this.v$.$validate();

                if(isValid)
                {
                    this.products.push({
                        id: this.productSelection.id,
                        name: this.productSelection.name,
                        quantity: this.quantity,
                        material_id: this.material,
                        requires_shade: this.productSelection.requires_shade,
                        is_aligner: this.productSelection.is_aligner,
                    });

                    this.$emit('update:modelValue', this.products);

                    this.modal.hide();
                }
                else
                {
                    console.log('Validation errors', this.v$.$errors.map((error) => { return error.$property + ': ' + error.$message}));
                }
            },
            remove: function(index)
            {
                this.products.splice(index, 1);
                this.$emit('update:modelValue', this.products);
            },
        },
    }
</script>
